<template>
<div>
  <div class="row m-0 lightgrey_bg" :class="{hiding: enq.hiding, clickable: responsePopup || actionPopup}" @click.stop="responsePopup = false; actionPopup = false" >
    <div class="col-12 p-0">
      <img :src="imgLink(enq.image)" :alt="enq.image" class="img_cut">
    </div>
    <div class="col-12 pt-4 pb-4 bg_darkest color_white medium text_ellipsis">
      {{ period }} | {{ noNights }}
    </div>
    <div class="col-12 pt-3 pb-3 bg_light medium">
      {{ enq.category ? enq.category : '&nbsp;' }}
    </div>
    </div>
    <div class="col-12 p-2 pt-0 bg_darkest color_white regular font5">
      <div class="row m-0 border_bottom pb-2 pt-2 align-items-center">
        <!-- <div class="col-2 p-0">
          <div class="initials montserrat_light font3 p-0">
            <center>
              EQ
            </center>
          </div>
        </div> -->
        <div class="col-11 p-0 pl-2 pr-2 font5">
          <div class="row m-0">
            <div class="col-12 p-0">
              Enquired on
            </div>
            <div class="col-12 p-0 pt-1">
              {{ dateTimeString(enq.createdAt) }}
            </div>
          </div>
        </div>
        <div class="col-1 p-0">
        </div>
      </div>
      <div class="row m-0 pb-2 pt-2 align-items-center" v-if="enq.quote && enq.quote.dateDelivered">
        <!-- <div class="col-2 p-0" v-tooltip="enq.quote.creator.name">
          <div class="initials montserrat_light font3 p-0">
            <center>
              {{ enq.quote.creator.initials }}
            </center>
          </div>
        </div> -->
        <div class="col-11 p-0 pl-2 pr-2 font5">
          <div class="row m-0">
            <div class="col-12 p-0">
              {{ typeComm(enq.quote) + ' on' }}
            </div>
            <div class="col-12 p-0 pt-1">
              {{ dateTimeString(enq.quote.dateDelivered) }}
            </div>
          </div>
        </div>
        <div class="col-1 p-0">
          <i class="material-icons" :class="{hovericon: responsesOpened || responsePopup}" @mouseover="mouseOverOpenResponseIcon(true)" @mouseleave="mouseOverOpenResponseIcon(false)" @click.stop="getItineraryPdf(enq.id)">chevron_right</i>
        </div>
      </div>
      <!-- <div class="row m-0 border_bottom pb-2 pt-2 align-items-center" v-else>
        <div class="col-12 p-0">
          <div class="initials_transp montserrat_light font3 p-0">
            <div class="col-2 p-0" v-tooltip="travelPlanner.name">
              <div class="initials montserrat_light font3 p-0">
                <center>
                  {{ travelPlanner.initials }}
                </center>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="row m-0 pt-2 justify-content-end">
        <div class="col-6 p-0 text-right">
          <i class="material-icons" :class="{hovericon: actionsClicked || actionPopup}" @mouseover="mouseOverActionIcon(true)" @mouseleave="mouseOverActionIcon(false)" @click.stop="openActionPopup">more_horiz</i> -->
          <!--ACTION POPUP-->
          <!-- <div class="action_popup white_background pt-1 pb-1 font4" v-if="actionPopup">
            <div class="row m-0 montserrat_light font12 text-left">
              <div class="col-12 p-1 clickable" @click.stop="showEnqDtls" :class="{highlited_menu_item: highlightedAction === 'details'}" @mouseover="mouseOverAction('details')" @mouseleave="mouseOverAction">
                Details
              </div>
              <div class="col-12 p-1 clickable" @click.stop="showEnqGuests" :class="{highlited_menu_item: highlightedAction === 'guests'}" @mouseover="mouseOverAction('guests')" @mouseleave="mouseOverAction">
                Guests
              </div>
            </div>
          </div> -->
          <!--END ACTION POPUP-->
        <!-- </div>
      </div> -->
    </div>
  </div>
  <!-- <enquiry-details v-if="showModalEnqDtls" :enquiry="fullEnquiry" @close="hideModalEnqDtls"></enquiry-details>
  <enquiry-guests v-if="showModalEnqGuests" :enqId="enq.enquiryId" @close="hideModalEnqGuests"></enquiry-guests>
  <quote-details v-if="showModalQuote" :fromCard="true" @close="hideModalQuoteDtls" @sent="sentSavedQuote" :quote="quote" :canForward=false :canSend=false :canConfirm=false></quote-details> -->
  <!-- <reassign-enquiry v-if="showModalReassignEnq" @close="hideModalReassignEnq" :enq="enq" :showTEs="!user.isTE"></reassign-enquiry> -->
  <!-- <change-enquiry-status v-if="showModalStatusChange" @close="hideModalStatusChange" :enq="enq" :changeType="statusChangeAction"></change-enquiry-status> -->
  <!-- <mark-contacted-enquiry v-if="showModalMarkAttended" @close="hideModalMarkAttended" :enq="enq" :showTEs="!user.isTE"></mark-contacted-enquiry> -->
  <!-- <reassign-guest v-if="showModalReassignGuest" @close="hideModalReassignGuest"
  :originalTE="defaultHandler"
  :prevEnquiries="prevEnquiries"
  :prevQuotes="prevQuotes"></reassign-guest> -->
</template>

<script>
import moment from 'moment'
import { mapGetters, mapActions } from 'vuex'
// const EnquiryDetails = () => import('../components/modals/EnquiryDetails')
// const EnquiryGuests = () => import('../components/modals/EnquiryGuests')
// const QuoteDetails = () => import('../components/modals/QuoteDetails')

export default {
  name: 'Card',
  // components: { EnquiryDetails, EnquiryGuests, QuoteDetails },
  props: {
    enq: {
      value: Object,
      default: null,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      responsePopup: false,
      actionPopup: false,
      fullEnquiry: null,
      highlightedAction: null,
      highlightedQuote: null,
      actionsClicked: false,
      responsesOpened: false,
      showModalEnqDtls: false,
      showModalEnqGuests: false,
      showModalQuote: false
    }
  },
  computed: {
    ...mapGetters([
      'enquiries',
      'quotes',
      'serverMedia',
      'travelPlanner'
    ]),
    noNights () {
      let n = this.enq.noNights ? this.enq.noNights : '&nbsp;'
      if (this.enq.noNights) {
        n += ' night'
        if (parseInt(this.enq.noNights) !== 1) {
          n += 's'
        }
      }
      return n
    },
    period () {
      return this.enq.period ? this.enq.period : '&nbsp;'
    }
  },
  methods: {
    ...mapActions([
      'getQuotes',
      'getItineraryPdf'
    ]),
    dateTimeString (s) {
      return moment(s).format('D MMM \'YY - hh:mm') + moment(s).format(' A').toLowerCase()
    },
    hasConfirmations (els) {
      if (els) {
        for (const el of els) {
          if (el.isConfirmation) {
            return true
          }
        }
      }
      return false
    },
    hideModalEnqDtls () {
      this.showModalEnqDtls = false
    },
    hideModalEnqGuests () {
      this.showModalEnqGuests = false
    },
    hideModalQuoteDtls (hideQuotesList) {
      this.showModalQuote = false
      if (hideQuotesList) {
        this.responsePopup = false
      }
    },
    imgLink (img) {
      if (img) {
        return this.serverMedia + 'images/small_landscape/' + img
      } else {
        return ''
      }
    },
    mouseOverAction (action) {
      this.highlightedAction = action
    },
    mouseOverActionIcon (isit) {
      this.actionsClicked = isit
    },
    mouseOverOpenResponseIcon (isit) {
      this.responsesOpened = isit
    },
    mouseOverQuote (q) {
      this.highlightedQuote = q
    },
    openActionPopup () {
      this.highlightedAction = null
      this.actionPopup = !this.actionPopup
    },
    showEnqDtls () {
      this.getEnquiry({ id: this.enq.enquiryId }).then((enq) => {
        this.fullEnquiry = enq
        this.showModalEnqDtls = true
      })
    },
    showEnqGuests () {
      this.showModalEnqGuests = true
    },
    showModalQuoteDtls (quote) {
      this.quote = quote
      this.showModalQuote = true
      this.ready = false
    },
    // showQuoteDtls (id) {
    //   this.setEnquiry(this.enq)
    //   this.getQuote(id).then((res) => {
    //     const q = res.quote
    //     // q.itinerary = this.enq.itinerary
    //     q.t = 0
    //     if (q.saved & !q.sent) {
    //       // this.canSendQuote = true
    //       // this.canForwardQuote = true
    //       // this.canConfirmQuote = false
    //       q.t = 1
    //     } else if (q.sent) {
    //       q.t = 2
    //       if (q.isConfirmation) {
    //         // this.canSendQuote = false
    //         // this.canForwardQuote = false
    //         // this.canConfirmQuote = false
    //         q.t = 3
    //       } else {
    //         // const isQuote = q.elements && q.elements.length > 0
    //         // this.canSendQuote = false
    //         // // this.canForwardQuote = true
    //         // this.canConfirmQuote = isQuote
    //         q.t = 4
    //       }
    //     }
    //     this.showModalQuoteDtls(q)
    //   })
    // },
    typeComm (q) {
      let type
      if (q.isConfirmation) {
        type = 'Confirmed'
      } else {
        if (q.isQuote) {
          type = 'Quoted'
        } else {
          type = 'Responded'
        }
      }
      return type
    }
  }
}
</script>

<style scoped>
  .assign img {
    border: 4px solid transparent;
  }
  .banner_img {
    position: absolute;
    top: 0;
    z-index: 99;
  }
  .assign img.clicked {
    border-color: #A7A8AA;
  }
  .badge {
   position: absolute;
   top:-7px;
   right:-37px;
   font-size:9px;
   font-weight:400;
   background:red;
   color:white;
   width:22px;
   height:14px;
   text-align:center;
   line-height:14px;
   border-radius:40%;
   padding: 0;
  }
  .bottom_right {
    top: 100%;
    right: -11px;
  }
  .enq-dtls {
    min-height: 25vh !important;
    font-size: 12px;
  }
  .enq-lodge {
    color: #fff;
    background-color: rgb(114,117,121);
    padding: 5px !important;
    height: 8vh !important;
  }
  .hiding {
    opacity: 0.4;
  }
  .img_cut {
    width: 100%;
    height: 150px;
    object-fit: cover;
  }
  .unopened[data-badge]:after {
    background:red;
  }
  .enquiry_number {
    position: absolute;
    top: 0;
    right: 20px;
    height: 38px;
    background-color: #231F20;
    z-index: 9;
    color: #fff;
  }
  .dark_bg {
    background-color: rgba(35, 31, 32, 0.8);
  }
  .medium_bg {
     background-color: rgba(35, 31, 32, 0.6);
  }
  .light_bg {
    background-color: rgba(35, 31, 32, 0.4);
  }
  .border_bottom {
    border-bottom: 1px solid #707070;
  }
  .initials {
    border: 1px solid ;
    line-height: 42px;
    height: 42px;
    width: 42px;
  }
  .initials_transp {
    line-height: 42px;
  }
  .material-icons {
    cursor: pointer;
  }
  .popup_holder {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 99;
    background: rgba(0,0,0,0.3);
  }
  .popup {
    max-height: 190px;
    overflow-y: auto;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
  }
  .action_popup {
    position: absolute;
    bottom: 2rem;
    right: 0px;
    width: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
  }
  .assign_popup {
    position: absolute;
    bottom: 2rem;
    left: 0px;
    width: 100%;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.4);
  }
  .hovericon {
    color: rgba(35, 31, 32, 0.8);
  }
  .red {
    background-color: red;
    cursor: pointer;
  }
  .red-text {
    color:red;
  }
  .orange {
    background-color: orange;
    cursor: pointer;
  }
  .underlined_te {
    text-decoration: underline;
  }
  .white_background {
    background: #fff !important;
    color: black;
  }

</style>
